'use client'

import * as IconSet from '@heroicons/react/24/outline'
import { useField, useFieldProps } from '@payloadcms/ui'
import { ReactSelect } from '@payloadcms/ui/elements/ReactSelect'
import { FieldDescription } from '@payloadcms/ui/fields/FieldDescription'
import { FieldError } from '@payloadcms/ui/fields/FieldError'
import { FieldLabel } from '@payloadcms/ui/fields/FieldLabel'
import { fieldBaseClass } from '@payloadcms/ui/fields/shared'
import clsx from 'clsx'
import type { OptionObject, TextFieldProps, TextFieldValidation, Validate } from 'payload'
import { FunctionComponent, useCallback, useMemo } from 'react'
import styles from './shared.module.scss'

export type IconType = keyof typeof IconSet

export const IconFieldComponent: FunctionComponent<TextFieldProps> = ({
  field, field: { name, _path, admin, label, maxLength, minLength, required },
  readOnly: readOnlyFromTopLevelProps,
  validate
}) => {
  const readOnlyFromProps = readOnlyFromTopLevelProps ?? admin?.readOnly
  const memoizedValidate = useCallback<TextFieldValidation>((value, options) => {
    if (typeof validate !== 'function') { return true }
    return validate(value, { ...options, maxLength, minLength, required })
  }, [validate, minLength, maxLength, required])
  const { path: pathFromContext, readOnly: readOnlyFromContext } = useFieldProps()
  const { formInitializing, formProcessing, path, setValue, showError, value } = useField<string>({
    path: pathFromContext ?? _path ?? name,
    validate: memoizedValidate as Validate
  })
  const disabled = readOnlyFromProps ?? readOnlyFromContext ?? formProcessing ?? formInitializing
  const options: OptionObject[] = Object.keys(IconSet).filter((key) => key !== 'default').map((item) => ({ label: item, value: item }))
  const renderOption = useCallback((val: string, size = 20) => {
    const option = options?.find((opt) => opt.value === val)
    if (!option?.value) { return undefined }
    const Icon = IconSet[option.value as IconType]
    return {
      label: (
        <div className={styles['row']}>
          <Icon width={size} height={size} style={{ minWidth: size }} />
          <span>{option.label.toString()}</span>
        </div>
      ) as unknown as string,
      value: option.value
    } as OptionObject
  }, [options])
  const valueToRender = useMemo<OptionObject | undefined>(() => renderOption(value, 16), [value, renderOption])
  return (
    <div className={clsx(fieldBaseClass, 'select', admin?.className, showError && 'error', disabled && 'read-only')}>
      <FieldLabel field={field} label={label} required={required} />
      <div className={`${fieldBaseClass}__wrap`}>
        <FieldError field={field} path={path} />
        <ReactSelect
          disabled={disabled}
          isClearable={!required}
          isMulti={false}
          onChange={(data) => { setValue(Array.isArray(data) ? data[0]?.value : data?.value) }}
          options={options!.map((option) => ({ ...option, label: renderOption(option.value, 20)?.label }))}
          value={valueToRender}
        />
      </div>
      <FieldDescription description={admin?.description} field={field} />
    </div>
  )
}
