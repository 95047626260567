'use client'

import { fieldBaseClass, FieldDescription, FieldError, FieldLabel, useField, useFieldProps } from '@payloadcms/ui'
import { ReactSelect } from '@payloadcms/ui/elements/ReactSelect'
import clsx from 'clsx'
import { OptionObject, TextFieldProps, TextFieldValidation, Validate } from 'payload'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { colorMap } from '../util/colors'
import styles from './shared.module.scss'

export const ColorFieldComponent: FunctionComponent<TextFieldProps> = ({
  field, field: { name, _path, admin, label, maxLength, minLength, required },
  readOnly: readOnlyFromTopLevelProps,
  validate
}) => {
  const readOnlyFromProps = readOnlyFromTopLevelProps ?? admin?.readOnly
  const memoizedValidate = useCallback<TextFieldValidation>((value, options) => {
    if (typeof validate !== 'function') { return true }
    return validate(value, { ...options, maxLength, minLength, required })
  }, [validate, minLength, maxLength, required])
  const { path: pathFromContext, readOnly: readOnlyFromContext } = useFieldProps()
  const { formInitializing, formProcessing, path, setValue, showError, value } = useField<string>({
    path: pathFromContext ?? _path ?? name,
    validate: memoizedValidate as Validate
  })
  const disabled = readOnlyFromProps ?? readOnlyFromContext ?? formProcessing ?? formInitializing
  const options: OptionObject[] = Object.keys(colorMap).map((item) => ({ label: item, value: item }))
  const renderOption = useCallback((val: string) => {
    const option = options.find((opt) => opt.value === val)
    if (!option?.value) { return undefined }
    const backgroundColor = colorMap[val]
    return ({
      ...option,
      label: (
        <div className={styles['row']}>
          <div className={styles['swatch']} style={{ backgroundColor }} />
          <div className='text-lg'>{option.label.toString()}</div>
        </div>
      ) as unknown as string
    })
  }, [options])
  const valueToRender = useMemo<OptionObject | undefined>(() => renderOption(value), [value, renderOption])
  return (
    <div className={clsx(fieldBaseClass, 'select', admin?.className, showError && 'error', disabled && 'read-only')}>
      <FieldLabel field={field} label={label} required={required} />
      <div className={`${fieldBaseClass}__wrap`}>
        <FieldError field={field} path={path} />
        <ReactSelect
          disabled={disabled}
          isClearable={!required}
          isMulti={false}
          onChange={(data) => { setValue(Array.isArray(data) ? data[0]?.value : data?.value) }}
          options={options.map((option) => ({ ...option, label: renderOption(option.value)?.label }))}
          value={valueToRender}
        />
      </div>
      <FieldDescription description={admin?.description} field={field} />
    </div>
  )
}
